import React from 'react'

export default function SectionHeading({ title, shortDescription, headClass, customClass='flex flex-col md:text-center' }) {
  return (
    <>
      <div className={"" + customClass}>
          <h2 className={"text-2xl font-semibold mb-4 leading-8 relative before:content-[' '] before:-bottom-1 before:h-[0.10rem] before:w-20 before:bg-[#1b5df3] before:absolute" + " " + headClass}>{title}</h2>
          {shortDescription &&
            <p className={shortDescription ? "text-base my-0 mb-8 block" : "hidden"}>{shortDescription}</p>
          }
      </div>
    </>
  )
}